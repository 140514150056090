import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        ['touchend', 'mouseup'].forEach((e) => {
            this.element.addEventListener(e, (event) => {
                event.preventDefault();
                document.body.classList.toggle('show-menu');    
                return false;
            });
        });

        window.addEventListener('pagehide', () => {
            document.body.classList.remove('show-menu');    
        });
        
        document.documentElement.addEventListener('turbo:before-render', () => {
            document.body.classList.remove('show-menu');
        });

        document.documentElement.addEventListener('click', (event) => {
            if (null === event.target.closest('#offcanvas') && null === event.target.closest('.menu-button')) {
                document.body.classList.remove('show-menu');
            }
        });
    }
}
