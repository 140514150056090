import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['placeholder', 'embed', 'button']
    static values = {
        cookieId: Number,
        permalink: String,
    }

    connect() {
        // We need to wait for DOM ready so that we have the cookiebar instance
        if ('loading' === document.readyState) {
            document.addEventListener('DOMContentLoaded', this.domLoadedEventListener = () => {
                this.addInstagramJs();
            });
        } else {
            this.addInstagramJs();
        }
    }

    accept() {
        // We need to wait for DOM ready so that we have the cookiebar instance
        if ('loading' === document.readyState) {
            document.addEventListener('DOMContentLoaded', this.domLoadedEventListener = () => {
                this.doAccept();
            });
        } else {
            this.doAccept();
        }
    }

    doAccept() {
        // Check if consent has not been given yet
        if (!cookiebar.issetCookie(this.cookieIdValue)) {
            // Store that consent has been given now within the cookiebar instance
            cookiebar.get().settings.cookies['_'+this.cookieIdValue].confirmed = true;

            // Update the cookiebar storage manuall to store the consent
            const storage = cookiebar.getStorage();
            storage.cookies.push(parseInt(this.cookieIdValue));
            localStorage.setItem(cookiebar.get().settings.token+'_'+cookiebar.get().settings.configId, JSON.stringify(storage));
        }

        // Now show the instagram embed
        this.buttonTarget.setAttribute('disabled', '');
        this.embedTarget.innerHTML = `<blockquote class="instagram-media instagram__media" data-instgrm-captioned data-instgrm-permalink="${this.permalinkValue}"></blockquote>`;
        this.addInstagramJs();

        // If instagram was already loaded, process embeds again
        if (!!window.instgrm) {
            window.instgrm.Embeds.process()
        }
    }

    disconnect() {
        if (this.domLoadedEventListener) {
            document.removeEventListener('DOMContentLoaded', this.domLoadedEventListener);
        }
    }

    addInstagramJs() {
        // Do not add instagram JS if consent has not been given or it was already loaded
        if (!cookiebar.issetCookie(this.cookieIdValue) || document.getElementById('instagram-embed')) {
            return;
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.instagram.com/embed.js';
        script.id = 'instagram-embed';
        document.body.appendChild(script);
    };

    loaded(event) {
        // If the loaded frame belongs to this element, remove the placeholder
        if (this.element.querySelector('#'+event.detail.frameId)) {
            this.placeholderTarget.remove();
        }
    }
}
