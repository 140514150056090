import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['media']

    connect() {
        // Detect safari
        if (!!window.GestureEvent) {
            // Clone the media element due to a bug in Safari when page is rendered with Turbo (https://github.com/hotwired/turbo/issues/892#issuecomment-1460561462)
            const mediaElement = this.mediaTarget
            const clone = mediaElement.cloneNode(true)
            mediaElement.parentNode.insertBefore(clone, mediaElement)
            mediaElement.remove()
        }
    }
}
