// controllers/search_controller.js
import { Controller } from '@hotwired/stimulus'
import { Swiper } from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default class extends Controller {
    static targets = [ 'wrapper', 'slide' ]

    connect() {
        new Swiper(this.element, {
            modules: [Pagination, Autoplay],
            speed: 600,
            autoplay: {
                delay: 6000,
            },
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            on: {
                afterInit: (swiper) => {
                    this.element.dispatchEvent(new CustomEvent('swiperInit', {detail: swiper}));
                },
                update: (swiper) => {
                    this.element.dispatchEvent(new CustomEvent('swiperUpdate', {detail: swiper}));      
                },
            }
        });
    }

    slideTargetConnected(element) {
        element.classList.add('swiper-slide');
    }
}
