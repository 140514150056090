import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

Utils.foreachElement('.mod_pageimage.pageimage--bottom', (pageimage) => {
    if (initialized.has(pageimage)) {
        return;
    }

    initialized.set(pageimage, true);

    const article = document.querySelector('.mod_article.article--teaser');

    if (null === article) {
        return;
    }

    const getPosition = function(element) {
        var clientRect = element.getBoundingClientRect();
        return {
            left: clientRect.left + document.body.scrollLeft + window.scrollX,
            top: clientRect.top + document.body.scrollTop + window.scrollY, 
            width: clientRect.width, 
            height: clientRect.height
        };
    }

    const update = (function update() {
        const doUpdate = (function doUpdate() {
            const position = getPosition(article);

            pageimage.style.top = position.top + 'px';

            return doUpdate;
        })();

        setTimeout(doUpdate, 500);
        setTimeout(doUpdate, 1000);
        setTimeout(doUpdate, 1500);

        return update;
    })();

    (function parallax() {
        const articleRect = getPosition(article);
        const position = articleRect.top;
        const figure = pageimage.querySelector('figure');
        const imageRect = pageimage.getBoundingClientRect();
        const effectHeight = articleRect.height - window.innerHeight;
        const transformHeight = Math.abs(articleRect.height - imageRect.height);
        const factor = (window.scrollY - position) / effectHeight;
        let translate = factor * transformHeight;
        const weekArticle = document.querySelector('.article--this-week');

        if (null !== weekArticle) {
            const weekArticleRect = getPosition(weekArticle);

            if (translate < 0 && Math.abs(translate) > weekArticleRect.height) {
                translate = weekArticleRect.height * -1;
            }
        }

        figure.style.transform = 'translateY('+(translate)+'px)';

        window.requestAnimationFrame(parallax);
    })();

    window.addEventListener('resize', update);
    window.addEventListener('load', update);
    document.documentElement.addEventListener('turbo:render', update);
    document.documentElement.addEventListener('turbo:frame-render', update);
});
