import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
    static targets = ['option']
    control = null;

    connect() {
        this.control = new TomSelect(this.element, {
            allowEmptyOption: true,
            controlInput: null
        })
    }

    disconnect() {
        this.control.destroy()
    }
}
