import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

Utils.foreachElement('.article--fixed', (article) => {
    if (initialized.has(article)) {
        return;
    }

    initialized.set(article, true);

    const dummy = document.createElement('div');
    dummy.classList.add('article-fixed-dummy');
    dummy.style.height = article.offsetHeight + 'px';

    const resize = (function resize(e) {
        article.style.width = article.parentNode.offsetWidth + 'px';
        dummy.style.height = article.offsetHeight + 'px';
        return resize;
    })();

    article.parentNode.insertBefore(dummy, article.nextSibling);
    article.classList.add('apply-fixed');

    document.addEventListener('DOMContentLoaded', resize);
    document.addEventListener('resizeTextFinished', resize);
    window.addEventListener('load', resize);
    window.addEventListener('resize', resize);
    document.documentElement.addEventListener('turbo:render', resize);
    document.documentElement.addEventListener('turbo:frame-render', resize);
    document.documentElement.addEventListener('turbo:load', resize);
    document.documentElement.addEventListener('turbo:frame-load', resize);
});
