import * as Utils from './utils';

'use strict';

export default function resizeText({ element, elements, minSize = 24, maxSize = 675, unit = 'px' }) {
    (elements || [element]).forEach(el => {
        el.style.wordBreak = 'break-all';

        const resize = (function resize(el) {
            const lineHeightPx = window.getComputedStyle(el).lineHeight;
            const fontSizePx = window.getComputedStyle(el).fontSize;
            const lineHeight = parseFloat(lineHeightPx) / parseFloat(fontSizePx);

            let step = 1;
            let stepSize = (maxSize - minSize) / 2;
            let fontSize = stepSize;

            do {
                el.style.fontSize = `${fontSize}${unit}`;
                stepSize /= 2;
                
                if (el.clientHeight > Math.ceil(fontSize * lineHeight)) {
                    fontSize -= stepSize;
                } else {
                    if (stepSize < 1) {
                        // Decrease font-size slightly due to hover issues
                        el.style.fontSize = `${fontSize - 1.25}${unit}`;

                        break;
                    }

                    fontSize += stepSize;
                }

                ++step;
            } while (step < maxSize / 2);

            document.dispatchEvent(new CustomEvent('resizeTextFinished', {detail: el}));            

            return resize;
        })(el);

        const debounceResize = Utils.debounce(() => { resize(el); }, 200);
        window.addEventListener('resize', debounceResize);
        window.addEventListener('load', () => { resize(el); });
        document.documentElement.addEventListener('turbo:render', () => { resize(el); });
        document.documentElement.addEventListener('turbo:frame-render', () => { resize(el); });

        const swiper = el.closest('.swiper');

        if (null != swiper) {
            swiper.addEventListener('swiperInit', () => { resize(el); });
        }
    });
};
