import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

Utils.foreachElement('.event-full .full-width-break', (fwb) => {
    if (initialized.has(fwb)) {
        return;
    }

    initialized.set(fwb, true);

    const event = fwb.closest('.event-full');
    const details = event.querySelector('.event-full__details');

    if (null === details) {
        return;
    }

    const index = Array.prototype.indexOf.call(details.parentElement.children, details);
    details.style.gridRow = 'span ' + (index + 1);
});

Utils.foreachElement('.event-full__dates-item--more', (more) => {
    if (initialized.has(more)) {
        return;
    }

    initialized.set(more, true);

    const datesContainer = more.closest('.event-full__dates');
    const datesItems = datesContainer.querySelectorAll('.event-full__dates-item--date');

    datesItems.forEach((date, index) => {
        if (index >= parseFloat(datesContainer.dataset.maxVisible)) {
            date.classList.add('hide');
        }
    });

    more.addEventListener('click', (e) => {
        e.preventDefault();

        datesItems.forEach((date) => {
            date.classList.remove('hide');
        });
        
        more.classList.add('hide');

        return false;
    });
});

Utils.foreachElement('.event-full__intro', (intro) => {
    if (initialized.has(intro)) {
        return;
    }

    initialized.set(intro, true);

    const getScrollPos = () => {
        let addSticky = false;

        if (intro.classList.contains('sticky')) {
            intro.classList.remove('sticky');
            addSticky = true;
        }

        const scrollPos = intro.getBoundingClientRect().top + document.documentElement.scrollTop;

        if (addSticky) {
            intro.classList.add('sticky');
        }

        return scrollPos;
    };

    (function updatePosition() {
        if (window.innerWidth < 1000) {
            intro.classList.remove('sticky');
            intro.removeAttribute('style');

            window.requestAnimationFrame(updatePosition);

            return;
        }

        const scrollPos = getScrollPos();

        if (document.documentElement.scrollTop >= scrollPos - 8) {
            if (!intro.classList.contains('sticky')) {
                intro.style.width = (intro.offsetWidth + 0.5) + 'px';
            }

            intro.classList.add('sticky');
        } else {
            intro.style.width = 'auto';
            intro.classList.remove('sticky');
        }

        const add = document.querySelector('.event-full__additional');

        if (null !== add) {
            const addPos = add.getBoundingClientRect().top + document.documentElement.scrollTop;

            if (document.documentElement.scrollTop + intro.offsetHeight + 40 >= addPos) {
                intro.style.top = (addPos - (document.documentElement.scrollTop + intro.offsetHeight + 40 - 8)) + 'px';
            } else {
                intro.style.top = '8px';
            }
        }

        window.requestAnimationFrame(updatePosition);
    })();
});
