const appHeight = (function appHeight() {
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');
    const doc = document.documentElement;

    doc.style.setProperty('--app-height', `${window.innerHeight}px`);

    if (null !== footer) {
        doc.style.setProperty('--footer-height', `${footer.offsetHeight}px`);
    }

    if (null !== header) {
        doc.style.setProperty('--header-height', `${header.offsetHeight}px`);
    }

    return appHeight;
})();

window.addEventListener('resize', appHeight);
window.addEventListener('load', appHeight);
