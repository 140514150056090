import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

let start = null;

Utils.foreachElement('.article--digital-stage-3d', (article) => {
    if (initialized.has(article)) {
        start = null;
        article.removeAttribute('data-clicked-stage');
        return;
    }

    initialized.set(article, true);

    article.querySelectorAll('[class^="ce_"]').forEach((element, i) => {
        element.addEventListener('click', () => {
            start = Date.now();
            article.setAttribute('data-clicked-stage', i+1);
        });
    });
});

document.addEventListener('turbo:before-render', async (event) => {
    if (start !== null) {
        event.preventDefault();

        await (async function() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, (start + 400) - Date.now());
            });
        })();
      
        event.detail.resume();
    }
});

document.addEventListener('turbo:render', () => {
    document.querySelectorAll('.article--digital-stage-3d').forEach((article) => {
        article.removeAttribute('data-clicked-stage');
    });
});
