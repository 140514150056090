import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

Utils.foreachElement('#header', (header) => {
    if (initialized.has(header)) {
        return;
    }

    initialized.set(header, true);

    const body = document.body;
    let lastScrollPos = null;
    let timeout = null;

    (function checkScrollPos() { 
        if (window.scrollY > 0) {
            document.body.classList.add('scroll--scrolled');
        } else {
            document.body.classList.remove('scroll--scrolled');
        }

        if (null !== lastScrollPos && lastScrollPos !== window.scrollY) {
            const delta = window.scrollY - lastScrollPos;

            body.classList.add('scroll--moving');
            body.classList.remove('scroll--stop');
            body.classList.remove('scroll--down');
            body.classList.remove('scroll--up');

            if (delta >= 0) {
                body.classList.add('scroll--down');
            } else {
                body.classList.add('scroll--up');
            }

            clearTimeout(timeout);
    
            timeout = setTimeout(() => {
                body.classList.remove('scroll--moving');
                body.classList.add('scroll--stop');
            }, 500);
        }

        if (null !== lastScrollPos || window.scrollY > 0) {
            lastScrollPos = window.scrollY;
        }

        window.requestAnimationFrame(checkScrollPos);
    })();

    header.addEventListener('mouseleave', () => {
        document.body.classList.remove('pull-down-menu');
    });
});

Utils.foreachElement('#logo', (logo) => {
    if (initialized.has(logo)) {
        return;
    }

    initialized.set(logo, true);

    logo.addEventListener('mouseenter', () => {
        document.body.classList.add('pull-down-menu');
    });
});
