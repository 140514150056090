export const debounce = (callback, wait) => {
    let timeout;
    return function(e) {
        clearTimeout(timeout);
    
        timeout = setTimeout(() => {
            callback(e);
        }, wait);
    }
};

const initializedMaps = [];

export const foreachElement = (selector, callback, trackInit) => {
    trackInit = typeof trackInit !== 'undefined' ? trackInit : false; 

    if (!initializedMaps[selector]) {
        initializedMaps[selector] = new WeakMap();
    }

    const initialized = initializedMaps[selector];

    const executeCallback = (element) => {
        if (trackInit) {
            if (initialized.has(element)) {
                return;
            }

            initialized.set(element, true);
        }

        callback(element);
    };

    document.querySelectorAll(selector).forEach(executeCallback);

    new MutationObserver(mutationsList => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach(function (element) {
                    if (element.matches && element.matches(selector)) {
                        executeCallback(element);
                    }

                    if (element.querySelectorAll) {
                        element.querySelectorAll(selector).forEach(executeCallback);
                    }
                })
            }
        }
    }).observe(document, {
        attributes: false,
        childList: true,
        subtree: true
    });
};
