import Macy from 'macy/dist/macy';
import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

Utils.foreachElement('select[name="time"][data-event-dates], select[name="alternative"][data-event-dates]', (time) => {
    const event = time.closest('form').querySelector('select[name="event"]');

    if (null === event) {
        return;
    }

    const dates = JSON.parse(time.dataset.eventDates);

    const updateOptions = (function updateOptions() {
        const options = [];

        if (dates[event.value]) {
            dates[event.value].forEach((d) => {
                options.push('<option value="'+d+'">'+d+'</option>');
            });
        }

        time.innerHTML = options.join();

        return updateOptions;
    })();

    event.addEventListener('change', updateOptions);
}, true);
